/**
 * Stringifies an object into one string with every pair separated by `&`
 *
 * @method toQueryString
 * @param data {Object} containing key-value pairs
 * @return {String} stringified presentation of the object, with every pair separated by `&`
*/
const toQueryString = data => {
    const keys = Object.keys(data);
    return keys.map(key => {
        const value = data[key];
        return (value === null) ? key : (key + '=' + encodeURIComponent(value));
    }).join('&');
};

module.exports = toQueryString;
